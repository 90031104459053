import pizzaCalculator from "../../assets/images/img/calculatorPizza.png"
import tasksApp from "../../assets/images/img/tasks_app.png"

export const mobileData = [
    {
        title: 'Pizza Dough Calculator',
        description: " This application was developed in React Native using Expo. With this pizza dough calculator app, you can create your own dough using a simple form. Fill in the\n\
         fields with your desired values, and get the result to start kneading. For saving the dough, and integrating Firebase for data storage and saving user-created dough recipes.",
        img: pizzaCalculator,
        imgLink: "https://github.com/rafaelmerlotto/pizza_dough_calculator",
        link: 'https://github.com/rafaelmerlotto/pizza_dough_calculator',
        techUsed: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
        techUsed2: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
        techUsed3: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-original.svg",
        techUsed4: "https://raw.githubusercontent.com/devicons/devicon/master/icons/android/android-original.svg",
    },
   /*  {
        title: 'Tasks app',
        description: "This app offers a straightforward solution for managing personal tasks, built with React Native using Expo. It includes a feature allowing users to mark tasks\n\
         as important or non-essential by simply tapping a bell icon. Additionally, there are dedicated displays for viewing all tasks and those that have been completed.",
        img: tasksApp,
        imgLink: "https://i.imgur.com/EEH7Uml.png",
        link: 'https://github.com/rafaelmerlotto/tasks_app',
        techUsed: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
        techUsed2: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
        techUsed3: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-original.svg",
        techUsed4: "https://raw.githubusercontent.com/devicons/devicon/master/icons/android/android-original.svg",
    } */
]