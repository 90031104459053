import imgtrackerFinance from '../../assets/images/img/app-tracker-responsive.png'
import imgBlogMedia from '../../assets/images/img/blog-media.png'
import imgAIVoices from '../../assets/images/img/ai-voices.png'
import imgTasks from '../../assets/images/img/tasks.png'
import imgWendy from '../../assets/images/img/portfolio_wendy.png'
import imgCvmotors from '../../assets/images/img/cv_motors.png'
import imgStockvet from '../../assets/images/img/stockVet.png'
import imgBatchCrafted from '../../assets/images/img/batchcrafted.png'

export const projectsData = [

    {
        title: 'SaaS Batch Crafted',
        description: "BatchCrafted is a SaaS platform designed for homebrewers to efficiently manage their brewing process from start to finish.\n\
         It provides an intuitive interface to track recipes, log brewing sessions, monitor fermentation progress, and analyze batch performance.",
        img: imgBatchCrafted,
        imgLink: "https://batchcrafted.com",
        link: 'https://batchcrafted.com',
        techUsed: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
        techUsed2: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
        techUsed3: "https://raw.githubusercontent.com/devicons/devicon/master/icons/ruby/ruby-original.svg",
        techUsed4: "https://raw.githubusercontent.com/devicons/devicon/master/icons/tailwindcss/tailwindcss-original.svg",
        techUsed5: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",


    },
    {
        title: 'C&V Motors',
        description: "Static website for a dealership designed to provide detailed information on available vehicles, services offered and contact details\n\
    Developed using React for functionality and Tailwind CSS along with MaterialUI for design",
        img: imgCvmotors,
        imgLink: "https://cv-motors.vercel.app/",
        link: 'https://cv-motors.vercel.app/',
        techUsed: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
        techUsed2: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
        techUsed3: "https://raw.githubusercontent.com/devicons/devicon/master/icons/tailwindcss/tailwindcss-original.svg"

    },
/*     {
        title: 'Stock management vet',
        description: "Application for managing the storage of veterinary products, developed using Ruby on Rails and adhering \n\
    to various best practices. PostgreSQL was utilized for the database management.",
        img: imgStockvet,
        imgLink: "",
        link: 'https://github.com/rafaelmerlotto/stock_vet',
        techUsed: "https://raw.githubusercontent.com/devicons/devicon/master/icons/ruby/ruby-original.svg",
        techUsed2: "https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original.svg",
        techUsed3: "https://raw.githubusercontent.com/devicons/devicon/master/icons/tailwindcss/tailwindcss-original.svg"

    }, */

    {
        title: 'Tracker finance',
        description: "This application was developed with the aim of personal finance management. It gives the user the ability to save or delete income,\n\
         expenses, and savings with real-time updates. Additionally, there is a functionality that allows the user to switch between five different currencies.",
        img: imgtrackerFinance,
        imgLink: "https://i.imgur.com/iufJsBt.png",
        link: 'https://github.com/rafaelmerlotto/tracker-finance',
        techUsed: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
        techUsed2: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
        techUsed3: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
        techUsed4: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
        techUsed5: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",

    },
    {
        title: 'Personal portfolio',
        description: "This static website serves as a personal portfolio showcasing my work. It focuses solely on the frontend aspect.\n\
         Developed using React for functionality and Tailwind CSS along with MaterialUI for design.",
        img: imgWendy,
        imgLink: "https://i.imgur.com/XGyigS9.png",
        link: 'https://github.com/rafaelmerlotto/project-api-todo',
        techUsed: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
        techUsed2: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
        techUsed3: "https://raw.githubusercontent.com/devicons/devicon/master/icons/tailwindcss/tailwindcss-original.svg"
    },
  /*   {
        title: 'Generate AI voices',
        description: "In this application, I utilized the OpenAI API to convert text into audio. The backend functions as a mechanism that extracts the voice name and text from the request body.\n\
         The frontend comprises a page where users can select their preferred voice, a text field for inputting the text to be transformed into audio, and an audio playback option for listening.",
        img: imgAIVoices,
        imgLink: "https://i.imgur.com/xzXM8Ic.png",
        link: 'https://github.com/rafaelmerlotto/openai_voice_app',
        techUsed: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
        techUsed2: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
        techUsed3: "https://raw.githubusercontent.com/devicons/devicon/master/icons/tailwindcss/tailwindcss-original.svg",
        techUsed4: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg"
    },
    {
        title: 'Personal Task Management',
        description: "Application for personal management of daily tasks: Provides the ability to create multiple boards and add tasks with difficulty levels,\n\
         start and end times, improve the organization of personal tasks.",
        img: imgTasks,
        imgLink: "https://i.imgur.com/14ojaxd.png",
        link: 'https://github.com/rafaelmerlotto/tasks_management',
        techUsed: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
        techUsed2: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
        techUsed3: "https://raw.githubusercontent.com/devicons/devicon/master/icons/tailwindcss/tailwindcss-original.svg",
        techUsed4: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg",
        techUsed5: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",

    }, */



    // {
    //     title: 'To Do List',
    //     description: "This is a simple To-Do List web app with basic features. It includes a text field to enter tasks and a button to save them.\n\
    //      Each task has a delete button for removal. The backend is built using Typescript, Express.js for API creation, and Prisma for database access.\n\
    //       The frontend is developed in React, with Typescript.",
    //     img: imgTodo,
    //     imgLink: "https://i.imgur.com/24txSgW.png",
    //     link: 'https://github.com/rafaelmerlotto/project-api-todo'
    // },

]